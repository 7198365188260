/* Reset for consistent styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure body and root take full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Courier Prime', monospace; /* Global typewriter font */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

/* App container */
.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Ensures content stretches */
}

/* Footer styles */
footer {
  width: 100%; /* Full width */
  background-color: #FF1493; /* Hot pink */
  color: #ffffff; /* White text */
  font-family: 'Courier Prime', monospace;
  text-align: center;
  padding: 10px 0;
}
